<template>
  <b-container class="mt-4 h-100">
    <div v-if="mode == 2">
      <div>
        <div
          class="h5"
          style="
            width: 100%;
            max-height: 60vh;
            inset: 0px;
            margin: auto;
            text-align: justify;
            overflow: auto;
          "
        >
          {{ question }}
        </div>
      </div>
      <div style="position: fixed; bottom: 0; left: 0" class="p-2 w-100">
        <!-- <b-button-group class="w-100 h-100">
          <b-button @click="sendYesOrNo(true)" class="w-50" variant="success"
            >Дэмжинэ</b-button
          >
          
        </b-button-group>
        <a @click="sendYesOrNo(false)" class="btn btn-danger w-50 " style="height: 100px" variant="warning"
            >Дэмжихгүй</a
          > -->

        <b-container
          class="bv-example-row bv-example-row-flex-cols"
          v-if="supportMode"
        >
          <b-row style="min-height: 10rem">
            <b-col
              @click="checkSanal(answers.filter((x) => x.ord == 10)[0]['id'])"
              align-self="center"
              class="bg-success text-center"
              style="
                margin-right: 10px;
                min-height: 10rem;
                align-items: center;
                display: flex;
                border-radius: 10px;
              "
            >
              <a class="text-center text-white h4" variant="success">
                {{ answers.filter((x) => x.ord == 10)[0].answer }}
              </a>
            </b-col>
            <b-col
              @click="checkSanal(answers.filter((x) => x.ord == 20)[0]['id'])"
              align-self="center"
              class="p-2 bg-danger text-center"
              style="
                margin-left: 10px;
                min-height: 10rem;
                align-items: center;
                display: flex;
                border-radius: 10px;
              "
            >
              <a class="text-center text-white h4" variant="success">
                {{ answers.filter((x) => x.ord == 20)[0].answer }}
              </a>
            </b-col>
          </b-row>
        </b-container>

        <b-container class="bv-example-row bv-example-row-flex-cols" v-else>

          <b-list-group style="overflow: scroll; max-height: 80vh;">
            <b-list-group-item style="margin-bottom: 20px;" @click="checkSanal(a.id)" v-for="(a, ai) in answers" :key="ai">{{ a.answer }}</b-list-group-item>
            <!-- <b-list-group-item @click="checkSanal(a.id)" v-for="(a, ai) in answers" :key="ai" 
              class="d-flex justify-content-between align-items-center">
              <b-badge variant="primary" pill>{{ ai+1 }}</b-badge>
              {{ a.answer }}
            </b-list-group-item> -->
          </b-list-group>
         
        </b-container>
      </div>
    </div>
    <div v-else-if="mode == 3">
      <div>
        <div
          class="h5"
          style="
            width: 80%;
            height: 100px;

            position: absolute;
            top: 0;
            bottom: 0;
            left: 0;
            right: 0;

            margin: auto;
            text-align: center;
          "
        >
          {{ msg }}
        </div>
      </div>
    </div>
  </b-container>
</template>

<script>
import { request } from "@/api/all";

export default {
  name: "Home",
  components: {},
  data() {
    return {
      msg: null,
      mode: 2,
      question: "",
      // yesCount: null,
      // noCount: null,
      qr: null,
      // percYes: null,
      // percNo: null,
      id: null,
      answers: [],
      supportMode: false,
    };
  },
  mounted() {
    let self = this;
    if (
      this.$cookie.get("token") != undefined &&
      this.$cookie.get("token") != null &&
      this.$cookie.get("token") != ""
    ) {
      //console.log("test");
      this.init();

      this.$store.state.signalr.osrCon.on("receiveNewQuestion", () => {
        //self.$router.push({ name: "poll", params: { id: res.id } });
        self.init();
        //console.warn('step6', res, self);
      });
    } else {
      this.$router.push({ name: "auth" });
    }
  },
  methods: {
    checkSanal(answerId) {
      request({
        api: { method: "POST", url: "Sanal/Check" },
        data: {
          questionId: this.id,
          answerId: answerId,
        },
        store: this.$store,
      }).then((res) => {
        if (res != false) {
          this.msg = "Баярлалаа.";
          this.mode = 3;
        } else {
          //this.$router.push({name: 'home'});
          window.location.href = "/#/t";
        }
      });
    },
    init() {
      this.mode = 2;
      request({
        api: { method: "GET", url: "Question/Detail" },
        store: this.$store,
      }).then((res) => {
        if (res != false) {
          this.id = res.data.id;
          this.supportMode = res.data.supportMode;
          this.question = res.data.question;
          this.answers = res.data.answers;
        } else {
          window.location.href = "/";
        }
      });
    },
  },
};
</script>
