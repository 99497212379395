<template>
  <div class="container-fluid">
    <div class="row m-3">
      <div class="col-11">
        <!-- <div class="row">
          <div class="col-2">
            Эхлэх огноо
            <b-form-datepicker v-model="filter.startDate"></b-form-datepicker>
          </div>
          <div class="col-2">
            Дуусах огноо
            <b-form-datepicker v-model="filter.endDate"></b-form-datepicker>
          </div>
          <div class="col-2">
            <div class="btn-group">
              <a @click="init()" class="btn btn-primary">Хайх</a>  
              <a @click="initReset()" class="btn btn-default">Бүгд</a>  
              
              <b-button v-if="loading" variant="warning" disabled>
                  <b-spinner small type="grow"></b-spinner>
                  Loading...
              </b-button>
              <a v-else @click="excel()" class="btn btn-success">Excel</a>  
            </div>
          </div>
          
        </div> -->
        <div class="btn-group">
          <a @click="completeQuestion()" class="btn btn-info"
            >Санал хураалтыг дуусгах</a
          >
          <a @click="deleteQuestion()" class="btn btn-warning">Санал хураалтыг устгах</a>
        </div>
      </div>
      <div class="col-1">
        <a @click="logOut" class="btn btn-danger">Гарах?</a>
      </div>
    </div>

    <b-container>
      <div class="row">
        <div class="col-8">
          <b-form @submit.prevent="saveq">
            <b-form-group
              id="input-group-1"
              label="Асуултын төрөл"
              label-for="input-1"
              description="Чагтгүй бол дурын хариулт оруулах боломжтой"
            >
              <b-form-checkbox
                rows="3"
                max-rows="6"
                id="input-1"
                v-model="form.supportMode"
                placeholder="Асуулт"
                required
              ></b-form-checkbox>
            </b-form-group>
            <b-form-group
              id="input-group-1"
              label="Асуулт"
              label-for="input-1"
              description="хадгалах дарснаар шинэ асуулт дэлгэцэнд шууд гарна"
            >
              <b-form-textarea
                rows="3"
                max-rows="6"
                id="input-1"
                v-model="form.question"
                placeholder="Асуулт"
                required
              ></b-form-textarea>
            </b-form-group>
            <b-form-group label="Хариултууд" v-if="form.supportMode == false">
              <!-- {{ form.answers.length }} -->
              <a
                @click="addAnswer"
                class="btn btn-success"
                style="margin-bottom: 10px"
                >Хариулт нэмэх</a
              >
              <div
                v-for="(as, ai) in form.answers"
                :key="`d_${ai}`"
                style="display: flex; margin-bottom: 10px"
              >
                <b-form-input
                  :key="`in_${ai}`"
                  v-model="as.answer"
                  type="text"
                  :placeholder="`Хариулт бичнэ үү`"
                ></b-form-input>
                <b-button
                  variant="danger"
                  @click="deleteAnswer(as)"
                  :key="`db_${ai}`"
                  >устгах</b-button
                >
              </div>
            </b-form-group>

            <b-button type="submit" variant="primary">Хадгалах</b-button>
            <!-- <b-button type="reset" variant="danger">Reset</b-button> -->
          </b-form>
        </div>
        <div class="col-4">
          <b-form @submit.prevent="savet">
            <b-form-group
              id="input-group-1"
              label="Санал хураалтад оролцоогүй хэрэглэгчийг дэмжээгүйд тооцох эсэх"
              label-for="input-1"
              description=""
            >
              <b-form-checkbox
                rows="3"
                max-rows="6"
                v-model="config.sanaldOroltsooguigDemjeeguidTootsoh"
                placeholder="Асуулт"
                required
              ></b-form-checkbox>
            </b-form-group>
            <b-form-group
              label="Ирц"
              description="нийт оролцогчдын тоо"
            >
              <b-form-input
                type="number"
                v-model="config.userCount"
                placeholder="Асуулт"
                required
              ></b-form-input>
            </b-form-group>

            <b-button type="submit" variant="primary">Хадгалах</b-button>
            <!-- <b-button type="reset" variant="danger">Reset</b-button> -->
          </b-form>
        </div>
      </div>
      
    </b-container>

    <!-- <b-table id="sms-table" striped hover :items="items"
    ></b-table> -->

    <!-- <div class="text-center">
      <b-pagination
        @change="pageChanged"
        v-model="currentPage"
        :total-rows="itemsLength"
        :per-page="perPage"
      ></b-pagination> 
      <div>Нийт: {{itemsLength}}</div>
    </div> -->
  </div>
</template>

<script>
//const moment = require("moment");
import { request } from "@/api/all";
import { uuid } from "vue-uuid";

export default {
  data() {
    return {
      config:{
        userCount: null,
        sanaldOroltsooguigDemjeeguidTootsoh: false,
      },
      form: {
        question: null,
        supportMode: true,
        answers: [],
      },
      newq: {
        modal: false,
      },
      items: [],
      itemsLength: 0,
      perPage: 5,
      currentPage: 1,
      filter: {
        startDate: null,
        endDate: null,
      },
      loading: false,
    };
  },
  mounted() {
    if (
      this.$cookie.get("token") != undefined &&
      this.$cookie.get("token") != null &&
      this.$cookie.get("token") != ""
    ) {
      this.init();
      this.form.answers.push({ answer: "", id: uuid.v1() });
      // this.form.answers.push({answer:''});
      // this.form.answers.push({answer:''});
      // this.form.answers.push({answer:''});
      // this.form.answers.push({answer:''});
      this.getSttng();
    } else this.$router.push({ name: "Home" });
  },
  methods: {
    getSttng(){
      request({
        api: { method: "GET", url: "Config/GetSetting" },
        store: this.$store,
      }).then((res) => {
        if (res != false) {
          this.config= res.data.config;
          console.log("res", res);
        }
      });
    },
    completeQuestion() {
      let yesOrNo = confirm("Санал хураалтыг дуусгах уу?");

      if (yesOrNo) {
        request({
          api: { method: "POST", url: "Question/Complete" },
          data: this.form,
          store: this.$store,
        }).then((res) => {
          if (res != false) {
            // this.form = {
            //   question: null,
            //   supportMode: true,
            //   answers: [],
            // };
            // //console.log("res", res);
          }
        });
      }
    },
    deleteAnswer(as) {
      let i = this.form.answers.indexOf(as);
      this.form.answers.splice(i, 1);
    },
    addAnswer() {
      //let self = this;
      this.form.answers.push({
        answer: "",
        id: uuid.v1(),
      });
    },
    savet(){
      request({
        api: { method: "POST", url: "Config/PostSetting" },
        data: this.config,
        store: this.$store,
      }).then((res) => {
        if (res != false) {
          alert('ok');
        }
      });
    },
    saveq() {
      // this.axios({
      //   url: "https://judsanal.gosmart.mn/core/Question/Add",
      //   method: "POST",
      //   data: {
      //     question: this.form.question,
      //   },
      //   headers: { Authorization: `Bearer ${this.$cookie.get("token")}` },
      // })

      request({
        api: { method: "POST", url: "Question/Add" },
        data: this.form,
        store: this.$store,
      }).then((res) => {
        if (res != false) {
          this.form = {
            question: null,
            supportMode: true,
            answers: [],
          };
          //console.log("res", res);
        }
      });
    },
    init() {
      // this.items=[];
      // this.itemsLength=0;
    },
    deleteQuestion() {
      let yesOrNo = confirm("Санал хураалтыг устгах уу?");

      if (yesOrNo) {
        request({
          api: { method: "POST", url: "Question/Delete" },
          store: this.$store,
        }).then((res) => {
          if (res != false) {
            this.form = {
              question: null,
              supportMode: true,
              answers: [],
            };
            // this.$cookie.set("token", "");
            // this.$router.push({ name: "Home" });
          }
        });
      }
    },
    logOut() {
      let yesOrNo = confirm("Гарах дарснаар идэвхитэй санал хураалт устахыг анхаарна уу !");

      if (yesOrNo) {
      request({
        api: { method: "POST", url: "Question/Delete" },
        store: this.$store,
      }).then((res) => {
        if (res != false) {
          this.$cookie.set("token", "");
          alert('Санал хураалтыг амжилттай устгалаа.');
          this.$router.push({ name: "Home" });
        }
      });
      }
    },
  },
};
</script>

<style></style>
