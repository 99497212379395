<template>
  <b-container class="mt-4 h-100" fluid>
    <div v-if="mode==1">
      <div>
        <div class="h5" style="
            width: 80%;
            height: 100px;

            position: absolute;
            top: 0;
            bottom: 0;
            left: 0;
            right: 0;

            margin: auto;
            text-align: center;
          ">
          Баярлалаа. <br />Та дараагийн санал хураалт эхлэх үед QR кодыг шинээр уншуулна уу.
        </div>
      </div>
    </div>
    <div v-else-if="mode==2">
      <div>
        <div class="h1" style="
            width: 80%;
            height: 100px;

            position: absolute;
            top: 0;
            bottom: 0;
            left: 0;
            right: 0;
            font-size: 10rem;
            margin: auto;
            text-align: center;
          ">
          {{timerCount}}
        </div>
      </div>
    </div>
  </b-container>
</template>

<script>

export default {
  name: "T",
  components: {},
  data(){
    return{
      mode: 1,
      timerEnabled: false,
      timerCount: 5
    }
  },
  methods:{
    play() {
                this.timerEnabled = true;
            },
  },
  watch: {

timerEnabled(value) {
  if (value) {
    setTimeout(() => {
      this.timerCount--;
    }, 1000);
  }
},

timerCount: {
  handler(value) {

    if (value > 1 && this.timerEnabled) {
      setTimeout(() => {
        this.timerCount--;
      }, 1000);
    }else{
      //alert(this.timerEnabled+' '+ value)
      if(this.timerEnabled)
      window.location.href = "/";
    }
  },
  immediate: true // This ensures the watcher is triggered upon creation
}

}
,
  mounted() {
    let self= this;
    if (
      this.$cookie.get("token") != undefined &&
      this.$cookie.get("token") != null &&
      this.$cookie.get("token") != ""
    ) {
      //console.error("step 4", this.$store);
      //this.init();
      this.$store.state.signalr.osrCon.on("receiveNewQuestion", () => {
        self.mode= 2;
        self.play();
        //self.init();
      });
    } else {
      this.$router.push({ name: "auth" });
    }
  },
};
</script>
