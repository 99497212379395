<template>
  <div class="container" style="margin-top: 20vh; max-width: 300px">
    <b-form @submit.prevent="onSubmit">
      <b-form-group label="Карт дээрх 4 оронтой дугаарыг оруулна уу">
        <!-- <b-form-input
          class="mb-1"
          v-model="form.email"
          placeholder="Нэвтрэх нэр"
        ></b-form-input> -->
        <b-form-input
          v-model="form.password"
          type="tel"
          placeholder="Дугаар"
          :formatter="formatYear"
        ></b-form-input>
      </b-form-group>

      <b-spinner v-if="ldng" label="Spinning"></b-spinner>
      <b-button v-else type="submit" variant="primary">Нэвтрэх</b-button>
    </b-form>
  </div>
</template>

<script>
import { mapActions } from "vuex";
import { request } from "@/api/all";

export default {
  name: "Home",
  components: {},
  data() {
    return {
      ldng: false,
      form: {
        email: "penalty",
        password: "",
      },
    };
  },
  mounted() {
    if (
      this.$cookie.get("token") != undefined &&
      this.$cookie.get("token") != null &&
      this.$cookie.get("token") != ""
    ) {
      this.$router.push({ name: "Home" });
    }
  },
  methods: {
    ...mapActions(["handleSignalR"]),
    formatYear(e) {
      return String(e).substring(0, 4);
    },
    onSubmit() {
      this.ldng = true;
      //console.log('sdf');

      request({
        api: { method: "POST", url: "JWT" },
        data: {
          // user: this.form.email,
          // password: this.form.password,
          code: this.form.password,
        },
        store: this.$store,
      }).then((res) => {
        if (res != false) {
          console.log(res);
          this.$cookie.set("token", res.token, 1);
          this.handleSignalR({ store: this.$store }).then(() => {
            this.$router.push({ name: "Home" });
          });
        } else {
          this.ldng = false;
          // this.$bvToast.toast(`дугаар буруу байна`, {
          //   title: "Мэдэгдэл",
          //   autoHideDelay: 5000,
          // });
        }
      });

      // this.axios({
      //   //url: 'https://smsapi.gosmart.mn/core/user/loginApi',
      //   url: "https://judsanal.gosmart.mn/core/JWT",
      //   method: "POST",
      //   data: {
      //     user: this.form.email,
      //     password: this.form.password,
      //     code: this.form.password,
      //   },
      // }).then((res) => {
      //   console.log("res", res);
      //   if (res.data.code == 200) {

      //     this.$cookie.set("token", res.data.token, 1);
      //     this.handleSignalR({ store: this.$store }).then(()=>{
      //       this.$router.push({ name: "Home" });
      //     })
      //     // this.$router.push({name: 'list'});

      //   } else {
      //     this.ldng= false;
      //     this.$bvToast.toast(`дугаар буруу байна`, {
      //       title: "Мэдэгдэл",
      //       autoHideDelay: 5000,
      //     });
      //   }
      // });
    },
    onReset() {},
  },
};
</script>
