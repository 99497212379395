<template>
  <b-container class="mt-4 h-100" fluid>
    <div>
      <div>
        <div class="h5" style="
            width: 80%;
            height: 100px;

            position: absolute;
            top: 0;
            bottom: 0;
            left: 0;
            right: 0;

            margin: auto;
            text-align: center;
          ">
          Түр хүлээнэ үү.
        </div>
      </div>
    </div>
  </b-container>
</template>

<script>

import { mapActions } from "vuex";
import { request } from "@/api/all";

export default {
  name: "L",
  components: {},
  methods: {
    ...mapActions(["handleSignalR"]),
  },
  mounted() {
    request({
        api: { method: "POST", url: "JWT" },
        data: {
          // user: this.form.email,
          // password: this.form.password,
          code: this.$route.params.v,
        },
        store: this.$store,
      }).then((res) => {
        if (res != false) {
          this.$cookie.set("token", res.token, 1);
          this.handleSignalR({ store: this.$store }).then(() => {
            this.$router.push({ name: "Home" });
          });
        } else {
          window.location.href = "/";
        }
      });
  },
};
</script>
